<template>
  <div>
    <div class="container">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-row>
        <div
          style="
            float: right;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 40%;
          "
        >
          <div
            @click="changeIndex(1)"
            :class="{ tabs: true, active: index == 1 }"
          >
            新售
          </div>
          <div
            @click="changeIndex(2)"
            :class="{ tabs: true, active: index == 2 }"
          >
            激活
          </div>
          <div
            @click="changeIndex(3)"
            :class="{ tabs: true, active: index == 3 }"
          >
            续卡
          </div>
          <div
            @click="changeIndex(4)"
            :class="{ tabs: true, active: index == 4 }"
          >
            本月到期
          </div>
        </div>
      </el-row>
    </div>
    <div class="container">
      <!-- <div class="zonglan">
      <div v-for="(zonglanitem,zonglanindex) in zonglan_right" :key="zonglanindex">
        <label>
          <span>{{zonglanitem.title}}</span>
        </label>
        <label>
          <span>{{zonglanitem.number}}</span>
          <img src="../../../static/img/bingtu.png" alt />
        </label>
      </div>
    </div> -->
      <div class="ontents">
        <div class="sec-title">兑换码发放记录</div>
      </div>
      <div class="search">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="用户名">
            <el-input v-model="name"></el-input>
          </el-form-item>
          <el-form-item label="账号">
            <el-input v-model="phone"></el-input>
          </el-form-item>
          <!-- <el-form-item label="锦鲤码">
          <el-input v-model="couponCode"></el-input>
        </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">查询</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div>
        <div class="contentList">
          <div class="paging">
            <el-table
              border
              ref="multipleTable"
              :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @filter-change="filterChange"
            >
              <el-table-column
                prop="id"
                align="center"
                label="id"
              ></el-table-column>

              <el-table-column
                :formatter="fromTitle"
                prop="map.getUserInfo.nick_name"
                align="center"
                label="发放用户"
              ></el-table-column>
              <el-table-column
                :formatter="fromTitle"
                prop="map.getUserInfo.mobile"
                align="center"
                label="手机号"
              ></el-table-column>
              <!-- <el-table-column prop="start_num" align="center"  label="卡号"></el-table-column> -->
              <el-table-column
                prop="coupon_code"
                align="center"
                label="兑换码"
              ></el-table-column>
              <el-table-column prop="type" align="center" label="卡类型">
                <template slot-scope="scope">
                  {{
                    scope.row.coupon_type == "尊享卡"
                      ? scope.row.is_pay
                        ? "尊享卡"
                        : "特殊尊享卡"
                      : scope.row.coupon_type
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="status" align="center" label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 1">已领取</span>
                  <span v-if="scope.row.status == 2">未领取</span>
                  <span v-if="scope.row.status == 4">过期</span>
                  <span v-if="scope.row.status == 5">未启用</span>
                  <span v-if="scope.row.status == 6">失效</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="create_time"
                align="center"
                width="200"
                label="发放时间"
              ></el-table-column>
              <el-table-column
                prop="overdue_time"
                align="center"
                width="200"
                label="过期时间"
              ></el-table-column>
              <el-table-column prop="" align="center" width="200" label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="small"
                    v-if="scope.row.status == 5"
                    @click="openit(scope.row, { status: 2 })"
                    >启用
                  </el-button>
                  <el-button
                    size="small"
                    v-if="scope.row.status == 2"
                    @click="openit(scope.row, { status: 5 })"
                    >关闭
                  </el-button>
                  <el-button
                    size="small"
                    v-if="
                      scope.row.coupon_type == '尊享卡' &&
                      scope.row.is_pay == true &&
                      (scope.row.status == 2 || scope.row.status == 5)
                    "
                    @click="openit(scope.row, { is_pay: false })"
                    >切换特殊尊享卡</el-button
                  >
                  <el-button
                    size="small"
                    v-if="
                      scope.row.coupon_type == '尊享卡' &&
                      scope.row.is_pay == false &&
                      (scope.row.status == 2 || scope.row.status == 5)
                    "
                    @click="openit(scope.row, { is_pay: true })"
                    >切换尊享卡</el-button
                  >
                  <el-button
                    size="small"
                    v-if="scope.row.status == 5 || scope.row.status == 2"
                    type="danger"
                    @click="openit(scope.row, 6)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!--分页-->
            <paging-fy
              @currentPageChange="handleCurrentChange"
              :currentPage="currentPage"
              :total="total"
            ></paging-fy>
          </div>
        </div>
        <input
          id="copy_content"
          type="text"
          value=""
          style="position: absolute; top: 0; left: 0; opacity: 0; z-index: -10"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveStreaming",
  components: {},
  data() {
    return {
      nav: {
        firstNav: "兑换码管理",
        secondNav: "领取记录管理",
      },
      tableData: [], //总数据
      stateCode: "",

      name: "",
      phone: "",
      couponCode: "",
      index: 1,
      form: {
        user: "",
        date1: "",
      },
      pageSize: 10,
      currentPage: 1,
      total: 0,
    };
  },
  mounted() {
    // this.GetNumbers();
    this.GetList();
  },
  methods: {
    fromTitle(row, column, cellValue) {
      if (cellValue === "" || cellValue == null) {
        return "暂无";
      } else {
        return cellValue;
      }
    },
    openit(row) {
      let url = "/user/couponList/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.GetList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    changeIndex(v) {
      this.index = v;
      switch (this.index) {
      }
    },
    filterChange(filterObj) {
      //  console.log(filterObj, "当列数据");
    },
    GetList() {
      this.$axios
        .get(
          "/user/couponList/queryManagerListPage?currentPage=" +
            this.currentPage
        )
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
          this.tableData.forEach((e) => {
            if (e.create_time > e.overdue_time) {
              this.stateCode = "已过期";
              e.stateS = this.stateCode;
            } else {
              this.stateCode = "已使用";
              e.stateS = this.stateCode;
            }
          });
        });
    },
    handleCurrentChange(val) {
      //  console.log(`当前页: `, val);
      this.currentPage = val;
      this.GetList();
    },
    onSubmit() {
      if (this.phone == "" && this.name == "") {
        this.GetList();
      } else {
        this.$axios
          .get(
            "/user/couponList/queryByMessage?" +
              "login_name=" +
              this.phone +
              "&nick_name=" +
              this.name
          )
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
            this.tableData.forEach((e) => {
              if (e.status == 1) {
                e.stateS = "已使用";
              } else if (e.status == 2 && !e.is_send) {
                e.stateS = "新售";
              } else if (e.status == 2 && e.is_send) {
              }
              if (e.create_time > e.overdue_time) {
                this.stateCode = "已过期";
                e.stateS = this.stateCode;
              } else {
                this.stateCode = "已使用";
                e.stateS = this.stateCode;
              }
            });
          });
      }
    },
    copy(row) {
      //  console.log(row);
      var clickContent = row.coupon_code;
      var inputElement = document.getElementById("copy_content");
      inputElement.value = clickContent;
      inputElement.select();
      document.execCommand("Copy");
    },
  },
};
</script>

<style>
.tabs {
  cursor: pointer;
  padding: 0.3rem;
  font-size: 1.5rem;
}
.tabs.active {
  color: #6d9fff;
  border-bottom: 1px solid #6d9fff;
}
</style>
